<template>
  <div class="recitals">
    <v-row>
      <popup-image contain
         class="col-1"
         src="/imgs/recital_2023_logo.png"
         popUpSrc="/imgs/2023_recital_info.jpg"></popup-image>
      <h1 class="col justify-center">2023 Spring Recital (Movie Soundtracks)</h1>
    </v-row>
    <vue-simple-markdown style="color:white" linkify source='
It’s time to talk Spring Recital, because we’re looking forward to it! The theme this year is movie soundtracks. Your dancers have been working very hard in their classes and this is a great chance for them to show off what they’ve learned throughout the year.

### Dates & Times
`February 1st:` Costumes fees are due ($50 per dancer per dance).
Enrollment is required through May 27th to participate in the recital.
`March 1st:` Recital fee is due ($50 per dancer).
`April 1st:` - Tickets go on sale.
Performing dancers: Covered
Under 2 years old:    Free
Pre-sale tickets:        $10 each
Tickets at door:         $12 each

`May 22rd - May 26th:` Please be on time in costume for in class dress rehearsals.
Group pictures will be taken during class times.
`May 27th:` - __Recital Day__ @ __Mountain View High School 2351 Sunny Hill Rd., Lawrenceville, GA 30043__
11:30pm - Dancers arrive at the theater dressed in costume and makeup.
12:30pm - Doors open to the audience with seating on a first come basis.
01:00pm - The show begins ON TIME

### Payments & Fees
ALL recital participants will be required to be on autopay tuition. Total fees for a single class is $100. If your dancer is enrolled in more than 1 class by February 1st  and wishes to perform in multiple dances, a costume fee of $50 per additional dance is also required. All fees associated with the recital are nonrefundable.. They will cover venue expenses such as lighting, sound, decor, programs, cleaning, costumes and recital shirts. Costume fees are time sensitive because they have to be ordered and shipped. The deadline cannot be extended. All fees and tuition must be paid for your dancers to participate in recital and are non refundable.

### Audience Etiquette
__No flash photography__ will be permitted during the show (capture/record without flash). No one is allowed backstage unless escorted by CTB staff (dancers under 5 may be accompanied by a guardian). These restrictions are for the safety of your dancers during this event.

### Your Dancer
Please be on time in costume and makeup. All dancers will remain backstage through the entire show. Your child will participate in their dance(s) as well as the finale. At the end of the show your dancer will receive a t-shirt and be ready for pickup by one parent at the front of the stage. The show will last approximately 2 hours.

We are looking forward to a great rest of the dance season and an amazing spring recital.

Questions? Contact us at dance.ctb@gmail.com | Thanks for being part of our dance family!

'></vue-simple-markdown>
  </div>
</template>

<style lang="scss">
.recitals .markdown-body {

  h3 {
    margin: 0;
  }

  code {
    color: #5c0202;
    background: none;
    font-size: 100%;
    font-weight: bolder;
  }
}
</style>
<script>
import PopupImage from '@/components/PopupImage';
export default {
  components: { PopupImage }
}
</script>
